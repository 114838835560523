@font-face {
    font-family: "Gilroy";
    font-style: normal;
    font-weight: bolder;
    src: url("./fonts/Gilroy-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Gilroy-BoldItalic";
    font-style: italic;
    font-weight: bolder;
    src: url("./fonts/Gilroy-BoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Gilroy-ExtraBold";
    font-style: normal;
    src: url("./fonts/Gilroy-ExtraBold.ttf") format("truetype");
}

@font-face {
    font-family: "Gilroy-ExtraBoldItalic";
    font-style: italic;
    src: url("./fonts/Gilroy-ExtraBoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Gilroy-SemiBoldItalic";
    font-style: italic;
    font-weight: normal;
    src: url("./fonts/Gilroy-SemiBoldItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Gilroy-Light";
    font-style: normal;
    font-weight: 300;
    src: url("./fonts/Gilroy-Light.ttf") format("truetype");
}

@font-face {
    font-family: "Gilroy-LightItalic";
    font-style: italic;
    font-weight: 300;
    src: url("./fonts/Gilroy-LightItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Gilroy-Medium";
    font-style: normal;
    font-weight: 500;
    src: url("./fonts/Gilroy-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "Gilroy-MediumItalic";
    font-style: italic;
    font-weight: 500;
    src: url("./fonts/Gilroy-MediumItalic.ttf") format("truetype");
}

@font-face {
    font-family: "Gilroy";
    font-style: normal;
    font-weight: normal;
    src: url("./fonts/Gilroy-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Gilroy-SemiBold";
    font-style: normal;
    font-weight: bold;
    src: url("./fonts/Gilroy-SemiBold.ttf") format("truetype");
}

@font-face {
    font-family: "Gilroy-Bold";
    font-style: normal;
    font-weight: 700;
    src: url("./fonts/Gilroy-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "Gilroy-SemiBoldItalic";
    font-style: italic;
    font-weight: bold;
    src: url("./fonts/Gilroy-SemiBoldItalic.ttf") format("truetype");
}

body {
    margin: 0;
    font-family: 'Gilroy' !important;
}

/* p{
    color: white;
} */
