$mainBgColor: #F1F1F1 !important;
$secondBgColor: #ffffff !important;

$blackColor1: #212121 !important;
$blackColor2: #1F1F1F !important;
$blackColor3: #0A0F1F !important;
$blackColor4: #3EBF8F !important;
$blackColor5: #DEF0FC !important;

$primaryColor: #004C95 !important;
$secondaryColor: #002951 !important;
$primaryColorWithAlpha: #3f71af27 !important;

$menuTextColor : #29292c;;
$primaryTextColor : #111111;
$primaryTextPlaceholderColor : #1111115b;

$borderColor1: #DDDFE3 !important;
$borderColor2: #E7E7E8 !important;
$borderColor3: #DDDFE3 !important;
$borderColor4: #CBD0DC !important;

$grayTextColor: #9E9E9E !important;
$grayTextColor2: #FCFCFC !important;
$grayTextColor3: #CFCFCF !important;
$grayTextColor4: #ABB4BA !important;
$grayTextColor5: #A9ACB4 !important;

.black-text {
    color: $blackColor1;
}

.primary-text {
    color: $primaryColor;
}
.secondary-text {
    color: $secondaryColor;
}

.gray-text {
    color: $grayTextColor;
}

.pointer {
    cursor: pointer;
}

.link-text {
    @extend .primary-text;

    @extend .pointer;
}

.primary-btn {
    color: white !important;
    background: linear-gradient(243.87deg, #002951 10.93%, #004c95 60.33%);
    font-size: 14px !important;
    font-weight: 700 !important;
}

.black-btn {
    color: white !important;
    background-color: $blackColor3;
    font-size: 14px !important;
    font-weight: 700 !important;
}

.btn {
    border-radius: 12px;
    padding: 8px 16px;
    color: white;
    font-size: 14px;
    margin: 8px;
    cursor: pointer;
}
.Chart {
    .wrapper {
        background: white;
        border-radius: 6px;
        padding: 30px;
        box-shadow: 0 0.25rem 1.125rem rgba(75, 70, 92, 0.1);

        canvas {
            width: 100% !important;
            height: 100% !important;
        }
    }
}
// export variables for using in JS files
:export {
    primaryColor: $primaryColor;
    primaryColorWithAlpha: $primaryColorWithAlpha;
    mainBgColor: $mainBgColor;
    secondBgColor: $secondBgColor;
    menuTextColor: $menuTextColor;
}

.css-j204z7-MuiFormControlLabel-root {
    margin-left: 0px !important;
    margin-right: 0px !important;
}